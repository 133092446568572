@font-face {
  font-display: swap;
  font-family: Bitter;
  font-style: normal;
  font-weight: 900;
  src: url("bitter-v32-latin-900.b7b81246.eot");
  src: url("bitter-v32-latin-900.b7b81246.eot#iefix") format("embedded-opentype"), url("bitter-v32-latin-900.e631fa51.woff2") format("woff2"), url("bitter-v32-latin-900.fd628712.woff") format("woff"), url("bitter-v32-latin-900.a7f7f8a1.ttf") format("truetype"), url("bitter-v32-latin-900.aa704204.svg#Bitter") format("svg");
}

@font-face {
  font-display: swap;
  font-family: Bitter;
  font-style: italic;
  font-weight: 900;
  src: url("bitter-v32-latin-900italic.977195a6.eot");
  src: url("bitter-v32-latin-900italic.977195a6.eot#iefix") format("embedded-opentype"), url("bitter-v32-latin-900italic.1d50aa8e.woff2") format("woff2"), url("bitter-v32-latin-900italic.c2f1664a.woff") format("woff"), url("bitter-v32-latin-900italic.dee5e1ac.ttf") format("truetype"), url("bitter-v32-latin-900italic.9c95cb3c.svg#Bitter") format("svg");
}

@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url("roboto-v30-latin-regular.5825acfa.eot");
  src: url("roboto-v30-latin-regular.5825acfa.eot#iefix") format("embedded-opentype"), url("roboto-v30-latin-regular.57844c26.woff2") format("woff2"), url("roboto-v30-latin-regular.32450484.woff") format("woff"), url("roboto-v30-latin-regular.8bedb902.ttf") format("truetype"), url("roboto-v30-latin-regular.dca3b2be.svg#Roboto") format("svg");
}

@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: url("roboto-v30-latin-italic.36179c74.eot");
  src: url("roboto-v30-latin-italic.36179c74.eot#iefix") format("embedded-opentype"), url("roboto-v30-latin-italic.b9b4832d.woff2") format("woff2"), url("roboto-v30-latin-italic.3dee9232.woff") format("woff"), url("roboto-v30-latin-italic.cb385173.ttf") format("truetype"), url("roboto-v30-latin-italic.d8fda2f0.svg#Roboto") format("svg");
}

@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url("roboto-v30-latin-700.9ea4456d.eot");
  src: url("roboto-v30-latin-700.9ea4456d.eot#iefix") format("embedded-opentype"), url("roboto-v30-latin-700.0e98339e.woff2") format("woff2"), url("roboto-v30-latin-700.df41cf3b.woff") format("woff"), url("roboto-v30-latin-700.ab0444c7.ttf") format("truetype"), url("roboto-v30-latin-700.be470e9e.svg#Roboto") format("svg");
}

@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: italic;
  font-weight: 700;
  src: url("roboto-v30-latin-700italic.8d4601a6.eot");
  src: url("roboto-v30-latin-700italic.8d4601a6.eot#iefix") format("embedded-opentype"), url("roboto-v30-latin-700italic.84bda3d4.woff2") format("woff2"), url("roboto-v30-latin-700italic.5e2bfc70.woff") format("woff"), url("roboto-v30-latin-700italic.850a711e.ttf") format("truetype"), url("roboto-v30-latin-700italic.a78eb07f.svg#Roboto") format("svg");
}

/*# sourceMappingURL=index.14d866bc.css.map */
