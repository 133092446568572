/* bitter-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/bitter-v32-latin-900.eot'); /* IE9 Compat Modes */
  src: url('../fonts/bitter-v32-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/bitter-v32-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/bitter-v32-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/bitter-v32-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/bitter-v32-latin-900.svg#Bitter') format('svg'); /* Legacy iOS */
}
/* bitter-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Bitter';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/bitter-v32-latin-900italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/bitter-v32-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/bitter-v32-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/bitter-v32-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/bitter-v32-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/bitter-v32-latin-900italic.svg#Bitter') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto-v30-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/roboto-v30-latin-700italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}